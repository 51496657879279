import moment from 'moment-timezone';


export function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "numeric", minute: "numeric", hour12: true }
  const oldDate = '01/01/1901 12:00:00 PM';
  if (Date.parse(dateString) < Date.parse(oldDate)) {
    return 'N/A'
  }

  return new Date(dateString).toLocaleDateString(undefined, options).replace(',', '');
}

export function formatInvoiceDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(undefined, options)
}


// Function that converts audit job run time (whatever the default server time is) to GMT/UTC.
// We want them to display as GMT/UTC with this function.
// So for example if you run the React servers on EST timezone, they will always show as GMT/UTC using this function.
export function formatAuditJobRunTimeDateServerTimeToGMT(dateString) {

  // Convert the input date string to a Date object.  The original date from the database for logs has no timezone, the timezone was
  // whatever the React server time was.   
  // ex.) dateString = 2023-08-22T09:54:17
  // ex.) originalDate = Tue Aug 22 2023 09:54:17 GMT-0600 (Mountain Daylight Time) - uses React Server's timezone of GMT-6.
  const originalDate = new Date(dateString);

  // Create options for the toLocaleString method to display in GMT/UTC
  const gmtOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };

  // Take the Server date/time and convert to UTC / GMT.
  const gmtLocalizedString = originalDate.toLocaleString('en-US', gmtOptions);
  //console.log("MK1: special... original: " + originalDate + ", gmtLocalized: " + gmtLocalizedString);

  return gmtLocalizedString;
}


// Get current UTC offset timezone from a date.  Returns a string.
// ex.) 2023-11-24T18:02:49.675Z w/420
// ex.) returns "UTC-07:00"
export function getTimeZoneUTCOffsetFormatFromBrowser(theDateTime) {
  // Get the time zone offset in minutes
  const timeZoneOffset = theDateTime.getTimezoneOffset();  // Get timezone offset of the current browser.
  // Convert the offset to hours and minutes
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60));
  const timeZoneOffsetMinutes = Math.abs(timeZoneOffset % 60);
  // Determine the sign of the offset (either '+' or '-')
  const sign = timeZoneOffset < 0 ? '+' : '-';
  // Format the time zone abbreviation or name (e.g., UTC+05:30)
  return (`UTC${sign}${timeZoneOffsetHours.toString().padStart(2, '0')}:${timeZoneOffsetMinutes.toString().padStart(2, '0')}`);
}

// Now given an IANA TimeZone string, get the UTC Offset.
// ex.) Input of "America/New York" returns "UTC-05:00"
export function getUTCOffsetString(timezone) {
  const now = moment().tz(timezone);
  const offsetMinutes = now.utcOffset();
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
  const offsetSign = offsetMinutes >= 0 ? '-' : '+';

  return (`UTC${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesRemainder.toString().padStart(2, '0')}`);
}



// PW: Nov 24, 2023: This method replaces getTenantsRecordTime2, we display the timezone provided.
export function getRecordedTime(theUtcDateTime, theIanaTimeZone) {
  // ex.) 'America/New_York'
  const theSpecificTimeZone = moment.utc(theUtcDateTime).tz(theIanaTimeZone);
  // Get the date 11/23/2023 3:42 PM
  const formattedDate = theSpecificTimeZone.format('MM/DD/YYYY h:mm:ss A');

  return formattedDate;
}



// PW: Nov 7, 2023: This method replaces getTenantsRecordedTime(theGMTXDateTime), since we fixed our datetimeoffsets to store UTC properly with a 0 offset.
export function getTenantsRecordedTime2(theUtcDateTime) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "numeric", minute: "numeric", hour12: true, timeZoneName: "short" }
  const oldDate = '01/01/1901 12:00:00 PM';
  if (Date.parse(theUtcDateTime) < Date.parse(oldDate)) {
    return 'N/A'
  }
  return new Date(theUtcDateTime).toLocaleDateString(undefined, options).replace(',', '');

}



/*
PW: Nov 7, 2023: This method is to be deprecated, we fixed a bug with how we were storing UTC times incorrectly, and hence this method was used to correct that.
As such this method should no longer be used and be replaced with getTenantsRecordedTime2(theUtcDateTime).
Displays as: 11/06/2023, 11:49:56 PM

Returns the time from the audit entry representing the time it was on the Tenant.
Database stores as (from Microsoft): 2023-08-03 17:54:49.0000000 -06:00
Creation time comes in from the WebAPI as: 2023-08-03T17:54:49-06:00
ex.) CreationTime = 2023-08-03 17:54:49.0000000 -06:00  (the -06:00 means GMT -6)
So in essense the time above tells us 17:54 - 6 hours = 11:54 am was the time for that item in that tenant's specific timezone (GMT-6).

We have confirmed this function works in various cases...
Case 1: 
  From database and MSFT: 2023-08-03 17:54:49.0000000 -06:00
  Creation Time: Thu Aug 03 2023 17:54:49 GMT-0600 (Mountain Time)  
  Returning Tenants Time: 08/03/2023, 11:54:49 AM
Case 2:
  From database and MSFT: 2023-08-03 17:54:49.0000000 -04:00
  Creation Time: Thu Aug 03 2023 17:54:49 GMT-0400 (Eastern Time)
  Returning Tenants Time: 08/03/2023, 01:54:49 PM
Case 3:
  From database and MSFT: 2023-08-03 03:01:01.0000000 -04:00
  Creation Time: Thu Aug 03 2023 03:01:01 GMT-0400 (Eastern Time)
  Returning Tenants Time: 08/02/2023 11:01:01 PM  
Case 4:
  From database and MSFT: 2023-08-03 03:01:01.0000000 -06:00
  Creation Time: Thu Aug 03 2023 03:01:01 GMT-0600 (Mountain Time)
  Returning Tenants Time: 08/02/2023 9:01:01 PM  
Case 5:
  From database and MSFT: 2023-08-03 17:54:49.0000000 +03:00
  Creation Time: Thu Aug 03 2023 17:54:49 GMT+0300 (Greece Time)
  Returning Tenants Time: 08/02/2023 08:54:49 PM  
*/
export function getTenantsRecordedTime(theGMTXDateTime) {

  // Extract date, time, and timezone offset from the input string
  const [datePart, timeAndOffset] = theGMTXDateTime.split("T");
  let negativeOffset = true;
  let [timePart, offset] = timeAndOffset.split("-");
  if (offset == null) {
    negativeOffset = false;
    [timePart, offset] = timeAndOffset.split("+");
  }

  const [year, month, day] = datePart.split("-");
  const [hour, minute, second] = timePart.split(":");
  const [timezoneHours, timezoneMinutes] = offset.split(":");

  // Create a new Date object with the local date and time components
  const localDate = new Date(
    parseInt(year),
    parseInt(month) - 1, // Months are 0-indexed in JavaScript
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
    parseFloat(second)
  );

  // Calculate the total offset in milliseconds
  let totalOffsetMilliseconds =
    ((parseInt(timezoneHours) * 60 + parseInt(timezoneMinutes)) * 60 * 1000);
  if (negativeOffset == true) {
    totalOffsetMilliseconds = totalOffsetMilliseconds * -1;
  }

  // Apply the offset to the date
  localDate.setTime(localDate.getTime() + totalOffsetMilliseconds);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, };
  const formattedDate = localDate.toLocaleString('en-US', options);

  /*
  console.log("MK: " +
      "GMT Date is:", theGMTXDateTime,
      "Local Date is:", localDate.toString(),
      "Timezone offset is:", totalOffsetMilliseconds / (60 * 1000) // Convert to minutes
  );
  // ex.) 08/03/2023, 11:54:50 AM
  console.log("MK: Tenant Date was:", formattedDate.toString());
  */

  return formattedDate.toString();
}



