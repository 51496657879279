// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { getTenantListFromUserPerms } from "../../utilities/common-user-utils";

// Function to insert our tenant dropdown list (textfield).
export default function TenantDropDown(props){

    const { user,
        company, 
        handleTenantOptionChange,
        selectedTenantOption} = props;

    return(
        
    <Grid container spacing={2} padding={0} paddingBottom={2}>
        <Grid item minwidth={100} style={{ display: 'flex', alignItems: 'center' }}><b>For Tenant:</b></Grid>
        <Grid item>
            <TextField
                    required
                    select
                    id="tenant-select"
                    value={selectedTenantOption}
                    onChange={handleTenantOptionChange}
                    helperText=""
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                    InputLabelProps={{ shrink: true }}
                >
                    {getTenantListFromUserPerms(user, company).map((tenant) => (
                        <MenuItem key={tenant.id} value={tenant}>
                            {tenant.tenantUrl}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>         
    </Grid>
    );
};