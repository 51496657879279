function catchAxiosErr2(e, sourceIdentifier, setLoading, setServiceIsDownError) {
    setLoading(false);
    if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(`${sourceIdentifier} Response Error:`);
        console.log(e.response.data);
        console.log(e.response.status);
        console.log(e.response.headers);
    } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the 
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(`${sourceIdentifier} Request Error:`);
        console.log(`Error Message: ${e.message}`);
        if (e.message == "Network Error") {
            setServiceIsDownError(true);
        }
        //console.log(e.request.message);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log(`${sourceIdentifier} Error:`, e.message);
    }
}

export default catchAxiosErr2;