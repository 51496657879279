// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


// Function to insert our Keyword selector (textfield).
export default function KeywordSelector(props){

    const { tenantId, selectedKeyword, setSelectedKeyword, selectedKeywordValue, errorSelectedKeyword } = props;

    return(
        
        <Grid item xs={12}>
            {/* User input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Keyword Search:</b>
                </div>
                <div>
                    <TextField                        
                        required
                        name="Keyword Search Text Field"
                        id="keywordTextfield"
                        placeholder="Enter a keyword to search for."
                        variant="outlined"
                        size="small"
                        style={{ width: 600 }}
                        inputProps={{ maxLength: 100 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setSelectedKeyword(event.target.value)}
                        inputRef={selectedKeywordValue}
                        value={selectedKeyword}
                        error={errorSelectedKeyword}
                        helperText={errorSelectedKeyword ? 'Keyword Search cannot be empty.' : ''}                     
                    />
                </div>
            </div>
        </Grid>
    );
};