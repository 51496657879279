
// Material UI Components
import Button from '@mui/material/Button';

const API_ID = process.env.REACT_APP_AUDIT_VAULT_APP_ID
const APP_URL = process.env.REACT_APP_WEB_URL;

export const GrantAccessButton = (props) => {
  const { company } = props
    
  const handleLogin = async () => {

      //let companyDomain = company.companyDomain.replace("sharepoint", "onmicrosoft")
      //let grantAccessUrl = `https://login.microsoftonline.com/${companyDomain}/adminconsent?client_id=${API_ID}&prompt=admin_consent&&redirect_uri=${APP_URL}grantaccess`;

      let grantAccessUrl = `https://login.microsoftonline.com/${company.authorizedByTenantId}/adminconsent?client_id=${API_ID}&prompt=admin_consent&redirect_uri=${APP_URL}grantaccess`;
      window.location.href=grantAccessUrl;

  };

  return (
    <div>
        <Button variant="contained" size="small" onClick={handleLogin}>Grant Consent</Button> 
    </div>
  );
}

export default GrantAccessButton;

//https://login.microsoftonline.com/{organization}/adminconsent?client_id={client-id}

//https://login.microsoftonline.com/saaphoenix.onmicrosoft.com/adminconsent?client_id=b5be4fae-1ccd-4c21-b810-420b4bcec04e