// React Components
import * as React from 'react';

// Material UI Components

// Audit Vault Components
import ReportTemplateActivityByTenantAndPeriodWithLineChart from './ReportTemplateActivityByTenantAndPeriodWithLineChart';

// Audit Vault Utilities

/*
Report returns All Teams Activity over a set period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- reportOnDate: The date from which the report is generated on (ex. today or yesterday, or 1 year ago). 
- displayDataSource: 0 or 1.  (if true displays table of sortable source data, if false includes link to report to view full details).
*/
export default function TeamsAllActivity(props) {

    // Component Constants
    const { user, companyId, tenantId, periodToUse, loadData, filterByColumn  } = props;        
    var theGraphTitle = "Teams All Activity";        

    // For the optional table to display data source.  815 px to work with.    
    const columns = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth:40, align: 'left' },        
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },       
        { id: 'teamName', label: 'Team Name', minWidth: 40, maxWidth: 85, align: 'left' },        
        { id: 'channelName', label: 'Channel Name', minWidth: 40, maxWidth: 85, align: 'left' },     
        { id: 'members', label: 'Members', minWidth: 100, maxWidth: 115, align: 'left' },        
        { id: 'attendees', label: 'Attendees', minWidth: 100, maxWidth: 115, align: 'left' }
    ];    
    
    // Component Functions

    // Component UI.
    return (
        <>        
            <ReportTemplateActivityByTenantAndPeriodWithLineChart 
                user={user}
                companyId={companyId}
                tenantId={tenantId} 
                periodToUse={periodToUse} 
                theGraphTitle={theGraphTitle} 
                reportType={"TeamsAllActivity"}
                columns={columns}
                loadData={loadData}
                filterByColumn={filterByColumn}  />
        </>



    );    
}
