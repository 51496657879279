const LINK_TO_ENABLE_AUDITING_ON_TENANT = "https://learn.microsoft.com/en-us/purview/audit-log-enable-disable";
const LINK_TO_TERMS_OF_SERVICE = "https://www.ecminsights.com/Home/Terms";
const LINK_TO_PRIVACY_POLICY = "https://www.ecminsights.com/Home/Privacy";
const LINK_TO_SAAS_CUSTOMER_AGREEMENT = "https://www.ecminsights.com/Home/SubscriptionServicesAgreement";
const LINK_TO_OUR_WEBSITE_HELP_CENTER = "https://www.ecminsights.com/helpcenter";
const LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE = "https://appsource.microsoft.com/en-us/product/office/WA200006762?tab=Overview";
const LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE = "https://ecminsights.com/HelpCenter/Audit-History-For-Sp-Menu-Deployment";

export {
    LINK_TO_ENABLE_AUDITING_ON_TENANT,
    LINK_TO_TERMS_OF_SERVICE,
    LINK_TO_PRIVACY_POLICY,
    LINK_TO_SAAS_CUSTOMER_AGREEMENT,
    LINK_TO_OUR_WEBSITE_HELP_CENTER,
    LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE,
    LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE
};