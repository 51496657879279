// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

// Audit Vault Components
import ProgressBar from "./common/ProgressBar";
import AccessDenied from "./common/AccessDenied";
import InvalidTenant from "./common/InvalidTenant";
import ServiceIsDown from "./common/ServiceIsDown";

// Audit Vault Utilities
import { getTenantStatus, getTenantStatusColour } from "../utilities/common-tenant-utils";
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTADMINISTRATOR } from "../constants/constants-roles";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";


export default function TenantStats(props) 
{
    const navigate = useNavigate();
    const { tenant, user, reportPageId } = props;
    const [stats, setStats] = useState("");
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [invalidTenantError, setInvalidTenantError] = useState(false); 


    async function fetchData()
    {
        try
        {
            if(loading)
            {
                if (tenant && user)
                {
                    if (user.companyId == tenant.companyId)
                    {
                        var response = await getHealthStatsByTenantId(tenant.id, reportPageId, user);
                        if (response) 
                        {
                            setStats(response);
                        }
                    }
                    else
                    {
                        setAccessDenied(true);
                    }
                }
            }
        }
        catch (e)
        {
            setAccessDenied(true); 
            console.log("ERROR: TenantStats: fetchData");
            console.log(e);
        }
        finally{
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }
    
    if (accessDenied) {
        console.log("Access denied")
        return (<AccessDenied></AccessDenied>);
    }    

    // Add check to see if invalid tenant error detected.
    if (invalidTenantError) {
        return (<><InvalidTenant></InvalidTenant></>);
    }

    if (loading)
    {
        return(<ProgressBar message="Loading ..." loading={loading} />);
    }

    return (
            <Card variant="outlined">
            <CardContent>
                For Tenant Domain: <b>{ tenant.tenantUrl }</b>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>Over the last 24 hours:</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>Health:</TableCell>                                                
                        <TableCell>
                            <Typography variant="h6" color={getTenantStatusColour(stats.status)} >
                                <CircleRoundedIcon color={getTenantStatusColour(stats.status)} sx={{paddingRight: 1, alignItems: "center"}} />
                                {getTenantStatus(stats.status)}
                            </Typography> 
                        </TableCell> 
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>Errors:</TableCell>
                        <TableCell>{stats.errorsIn24Hours}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>Records Processed:</TableCell>
                        <TableCell>{stats.totalExchangeAuditRecordsIn24Hours + stats.totalEntraIDAuditRecordsIn24Hours + stats.totalSharePointAuditRecordsIn24Hours + stats.totalTeamsAuditRecordsIn24Hours}</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                {verifyUserHasTenantRolePermissions(user, tenant.id, ROLE_CODE_TENANTADMINISTRATOR) &&
                    <Button size="small" onClick={() => navigate(`/tenant-stats/${tenant.id}`)}>See More</Button>
                }
            </CardActions>
            </Card>
        );
}