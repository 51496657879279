// React Components
import React from "react";
import { useEffect, useState, setState } from "react";

// Material UI Components
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { IANA_TIME_ZONES } from "../../constants/constants-timezones";

// Function to insert the IANA time zone textfield selection. 
// IANA timezones are retrieved from the web browser directly and are compatible with the DateTimeOffset and Moment dates.
// Windows timezones are not available from the web browser and are not as directly compatible with the DateTimeOffset and Moment dates.
// Hence we use IANA timezones along with UTC offset to provide the most accurate available selections.
export default function DisplayAndChooseTimezone(props){

    const { selectedTimeZone, handleTimeZoneChange} = props;

    useEffect(() => {
    }, []);


    return(       
        <TextField
            required
            select
            id="timezone-select"
            value={selectedTimeZone}
            onChange={handleTimeZoneChange}
            helperText=""
            variant="outlined"
            size="small"                        
            sx={{
                width: 200, // Adjust the width as needed
                height: 32, // Adjust the height of the TextField
                '& .MuiSelect-select': {
                    fontSize: '0.875rem', // Adjust the font size
                },
            }}
            InputLabelProps={{ shrink: true }}
        >            
            {IANA_TIME_ZONES.map((tz, index) => (
                <MenuItem key={index} value={tz}>
                    {tz}
                </MenuItem>
            ))}
        </TextField>        
    );


    
};