import { graphConfig, organizationConfig } from "./authConfig";

/*
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
};
*/

export async function callMsGraphOrg(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(organizationConfig.graphOrgEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
};

export async function callMsGraphPerms(accessToken, endPoint) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };
    return fetch(endPoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
};