// React Components
import * as React from 'react';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ProgressBar from "../common/ProgressBar";


export default function RoleInfoList(props) {

    // Component Constants
    const { 
      tenantId, 
      handleAccordionLoad,
      handleReviewMembers,
      handleAddMembers,
      handleRemoveAccess,
      acccordianExpand,
      roleName,
      roleDescription,
      roleId,
      loading,
      users
     } = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

console.log(users)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



// Component UI
return(
    <Accordion expanded={acccordianExpand}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        onClick={event => handleAccordionLoad(roleId) } 
        id="panel1a-header"
        >
        <Typography><b>{roleName}</b><br/>{roleDescription}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ProgressBar message="Loading ..." loading={loading} />
        <Table stickyHeader aria-label="sticky table">
        <TableBody>
        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            const perms = row.permissions;
            for(var i=0; i < perms.length; i++)
            {
                if ((perms[i].roleId == roleId && perms[i].tenantId == tenantId) ||
                    (roleId == 1 && perms[i].tenantId == 0))
                {
                    return(
                    <TableRow>
                    <TableCell key={'TA' + row.displayName+row.id}>{row.displayName}</TableCell>
                    <TableCell key={'TADEL' + row.displayName+row.id} sx={{textAlign: "right"}}>
                    <IconButton aria-label="delete"  onClick={(event) => handleRemoveAccess(perms[i].id, roleId, row.userEmail)}>
                        <DeleteIcon />
                    </IconButton>
                    </TableCell>
                    </TableRow>
                    )
                }
            
            }
        })}
        
        </TableBody>
        </Table>
        {users.length > 25 &&
        <TablePagination
                  rowsPerPageOptions={[25, 50, 75]}
                  component="span"
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage} />
        }
        </AccordionDetails>
        <AccordionActions>
            <Typography component="span" sx={{width:'100%'}}>
            <Button size="small" sx={{textAlign: "left"}} onClick={(event) => handleReviewMembers(roleId)}>See More</Button>
            </Typography>
            <IconButton sx={{textAlign: "right"}} aria-label="addUser" onClick={(event) => handleAddMembers(roleId)}>
                <PersonAddAlt1Icon sx={{ color: "green" }} />
            </IconButton>
            
        </AccordionActions>
    </Accordion>
)
}
  