const ROLE_CODE_SYSTEMADMINISTRATOR = 0;
const ROLE_CODE_COMPANYADMINISTRATOR = 1;
const ROLE_CODE_TENANTADMINISTRATOR = 2;
const ROLE_CODE_TENANTREPORTREADER = 3;
const ROLE_CODE_TENANTVIEWER = 4;
const ROLE_CODE_NOACCESS = 5;

const ROLES = [
    { code: '1', label: 'Company Administrator' },
    { code: '2', label: 'Tenant Administrator' },
    { code: '3', label: 'Tenant Report Reader' },
    { code: '4', label: 'Tenant Viewer' },
    { code: '5', label: 'No Access' },
];

export { ROLES, ROLE_CODE_SYSTEMADMINISTRATOR, ROLE_CODE_COMPANYADMINISTRATOR, ROLE_CODE_TENANTADMINISTRATOR, ROLE_CODE_TENANTREPORTREADER, ROLE_CODE_TENANTVIEWER, ROLE_CODE_NOACCESS };