
import dayjs from 'dayjs';

import { REPORT_ON_PERIOD_1WEEK, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_180DAYS, REPORT_ON_PERIOD_1YEAR } from "../constants/constants-reportperiods";


// Define a preset array of colors for use in a dataset that do not clash for the most part.
// We currently have 25, should be enough for most chart uses.
// We've chosen the colors here to contrast well for the first 10+ options at least.
export const presetDatasetColors = [
  'rgb(255, 0, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 0, 255)',
  'rgb(255, 0, 255)',
  'rgb(75, 192, 192)',
  'rgb(255, 205, 86)',
  'rgb(0, 255, 255)',
  'rgb(128, 0, 0)',
  'rgb(0, 128, 0)',
  'rgb(255, 255, 0)',
  'rgb(0, 0, 128)',
  'rgb(128, 128, 0)',
  'rgb(128, 0, 128)',
  'rgb(0, 128, 128)',
  'rgb(139, 69, 19)',
  'rgb(54, 162, 235)',
  'rgb(255, 159, 64)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  'rgb(255, 99, 132)',
  'rgb(165, 42, 42)',
  'rgb(0, 128, 0)',
  'rgb(255, 0, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 0, 255)',
];



// Function to combine DateJS date string and timestring to a Date object, and then returned as the convertDateToDateTimeOffset(theDate).
// For use with WebAPI scrubbed date range queries.
// PW: 2024/01/11...updated to use Zulu time to prevent SSL issues in production, production doesn't liek the "+" character when calling WebAPI controllers.
export function combineDateJsDateAndTimeToDateTimeOffset(dateString, timeString) {
  // Below is commented out on 2024/01/11 as per comment above.
  /*
  const date = dayjs(dateString, 'YYYY-MM-DD');
  const [hours, minutes] = timeString.split(':').map(Number);          
  const combinedDateTime = date.set('hour', hours).set('minute', minutes);
  console.log("ABC: " + timeString + ", " + hours + ", " + minutes);
  
  let retVal = convertDateToDateTimeOffset(combinedDateTime.toDate());
  return(retVal);
  */
  const date = dayjs(dateString, 'YYYY-MM-DD').toDate();
  const [hours, minutes] = timeString.split(':').map(Number);

  const utcDateTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  utcDateTime.setUTCHours(hours, minutes, 0, 0);  // Set start date time.  

  let retVal = convertDateToDateTimeOffset(utcDateTime);
  return (retVal);


}


// Private method called by determinePeriodDataPoints.  Enforce UTC offset of 0.
// PW: 2024/01/10...updated to use Zulu time format to prevent SSL issues in production, production doesn't like the "+" character when calling WebAPI controllers.
// Compatible with DateTimeOffset in backend WebAPI use in C#.
function convertDateToDateTimeOffset(theDate) {
  // Below is commented out on 2024/01/10 as per comment above.
  /*    
  let year = theDate.getFullYear();
  let month = String(theDate.getMonth() + 1).padStart(2, '0');
  let day = String(theDate.getDate()).padStart(2, '0');
  let hour = String(theDate.getHours()).padStart(2, '0');
  let minute = String(theDate.getMinutes()).padStart(2, '0');
  let second = String(theDate.getSeconds()).padStart(2, '0');
  return (
      `${year}-${month}-${day} ${hour}:${minute}:${second} +00:00`
  );
  */

  // Return the date in Zulu time which is compatible for UTC for DateTimeOffset use in C# WebAPI (backend).
  let retVal = theDate.toISOString();
  //console.log("SHOW: " + retVal);
  return (retVal);
}

// Function to join all array values to a semicolon seperated string.
export function convertArrayValuesToSemicolonSeperatedString(arr) {
  return arr.join(';');
}

// Function to take a semicolon seperated string and repopulate an array of strings.
export function convertSplitStringBySemicolonToArray(inputString) {
  // Use the split() method to split the input string by semicolons
  var trimmedArray = new Array;
  if (inputString != null) {
    const stringArray = inputString.split(';');
    // Remove any leading or trailing whitespace from each string in the array
    trimmedArray = stringArray.map((str) => str.trim());
  }
  return trimmedArray;
}


// Private method called by determinePeriodDataPoints.
function getLastDateOfMonth(year, month) {
  //return new Date(year, month + 1, 0);
  return new Date(Date.UTC(year, month + 2, 0));
}

/* 
Function prepares the reportInputData array for loading based on periodToUse (0-5) and reportOnDate (the date which to base the report on -1 day so the day is complete).
Must pass in a blank array for population.
periodToUse would be 0: 7 days, 1: 30 days, 2: 90 days, 3: 180 days, 4: 1 year.
So as long as we make the note that the dates in the charts are based on GMT times.    
*/
export function determinePeriodDataPoints(periodToUse, reportOnDate, reportInputData) {
  if (!Array.isArray(reportInputData)) {
    throw new Error('Invalid data structure. Please provide an array.');
  }

  switch (periodToUse) {
    case REPORT_ON_PERIOD_1WEEK:
      // 1 week. Use individual periods of 7 days (total 7 days).
      for (let i = 7; i >= 0; i--) {
        /*
        const startDate = new Date(reportOnDate); // Clone the reportOnDate to avoid modifying it.
        startDate.setDate(startDate.getDate() - i); // Decrement by (i + 1) days to go back from reportOnDate.                
        startDate.setHours(0, 0, 0, 0); // Set start date time to 00:00:00.
                    
        const endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.
        */
        const startDate = new Date(Date.UTC(reportOnDate.getFullYear(), reportOnDate.getMonth(), reportOnDate.getDate()));
        startDate.setUTCDate(startDate.getUTCDate() - i);  // Decrement by (i + 1) days to go back from reportOnDate.                
        startDate.setUTCHours(0, 0, 0, 0);  // Set start date time to 00:00:00.

        const endDate = new Date(startDate);
        endDate.setUTCHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.

        reportInputData.push({
          period: i,
          periodStartDate: convertDateToDateTimeOffset(startDate),
          periodEndDate: convertDateToDateTimeOffset(endDate),
        });
      }
      break;
    case REPORT_ON_PERIOD_30DAYS:
      // 1 month. Use period of 4 weeks (total 4 weeks).                  
      for (let i = 3; i >= 0; i--) {
        /*
        const startDate = new Date(reportOnDate); // Clone the reportOnDate to avoid modifying it.
        startDate.setDate(reportOnDate.getDate() - ((i+1) * 7) + 1 );
        startDate.setHours(0, 0, 0, 0); // Set start date time to 00:00:00.
 
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6); // Set to the 6th day (last day of the week).
        endDate.setHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.
        */
        const startDate = new Date(Date.UTC(reportOnDate.getFullYear(), reportOnDate.getMonth(), reportOnDate.getDate()));
        startDate.setUTCDate(startDate.getUTCDate() - ((i + 1) * 7) + 1);
        startDate.setUTCHours(0, 0, 0, 0);  // Set start date time to 00:00:00.

        const endDate = new Date(startDate);
        endDate.setUTCDate(startDate.getUTCDate() + 6); // Set to the 6th day (last day of the week).
        endDate.setUTCHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.

        reportInputData.push({
          period: i,
          periodStartDate: convertDateToDateTimeOffset(startDate),
          periodEndDate: convertDateToDateTimeOffset(endDate),
        });
      }
      break;
    case REPORT_ON_PERIOD_90DAYS:
      // 3 months (90 days). Use periods of 1 month (total of 4 month data points to cover our bases).
      for (let i = 0; i < 4; i++) {
        /*
        const startDate = new Date();
        startDate.setYear(reportOnDate.getFullYear());
        startDate.setMonth(reportOnDate.getMonth() - (3 - i));
        startDate.setDate(1); // Set the start date to the 1st day of the month.
        startDate.setHours(0, 0, 0, 0); // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setHours(23, 59, 59, 999); // Set end date to 23:59:59 of the last day of the corresponding month.
        */

        const startDate = new Date(Date.UTC(reportOnDate.getFullYear(), (reportOnDate.getMonth() - (3 - i)), 1));  // Set the start date to the 1st day of the month.            
        startDate.setUTCHours(0, 0, 0, 0);  // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setUTCHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.

        reportInputData.push({
          period: i,
          periodStartDate: convertDateToDateTimeOffset(startDate),
          periodEndDate: convertDateToDateTimeOffset(endDate),
        });
      }
      break;
    case REPORT_ON_PERIOD_180DAYS:
      // 6 months (180 days). Use periods of 1 month (total of 7 month data points to cover our bases).
      for (let i = 0; i < 7; i++) {
        /*
        const startDate = new Date();
        startDate.setYear(reportOnDate.getFullYear());
        startDate.setMonth(reportOnDate.getMonth() - (6 - i));
        startDate.setDate(1); // Set the start date to the 1st day of the month.
        startDate.setHours(0, 0, 0, 0); // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setHours(23, 59, 59, 999); // Set end date to 23:59:59 of the last day of the corresponding month.
        */

        const startDate = new Date(Date.UTC(reportOnDate.getFullYear(), (reportOnDate.getMonth() - (6 - i)), 1));  // Set the start date to the 1st day of the month.            
        startDate.setUTCHours(0, 0, 0, 0);  // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setUTCHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.

        reportInputData.push({
          period: i,
          periodStartDate: convertDateToDateTimeOffset(startDate),
          periodEndDate: convertDateToDateTimeOffset(endDate),
        });
      }
      break;
    case REPORT_ON_PERIOD_1YEAR:
      // 1 year. Use periods of 1 month (total of 13 month data points to cover our bases).
      for (let i = 0; i < 13; i++) {
        /*
        const startDate = new Date();
        startDate.setYear(reportOnDate.getFullYear());
        startDate.setMonth(reportOnDate.getMonth() - (12 - i));
        startDate.setDate(1); // Set the start date to the 1st day of the month.
        startDate.setHours(0, 0, 0, 0); // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setHours(23, 59, 59, 999); // Set end date to 23:59:59 of the last day of the corresponding month.
        */

        const startDate = new Date(Date.UTC(reportOnDate.getFullYear(), (reportOnDate.getMonth() - (12 - i)), 1));  // Set the start date to the 1st day of the month.            
        startDate.setUTCHours(0, 0, 0, 0);  // Set start date time to 00:00:00.

        let endDate = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth()); // Get the last date of the corresponding month.
        endDate.setUTCHours(23, 59, 59, 999); // Set end date to 23:59:59 of each day.

        reportInputData.push({
          period: i,
          periodStartDate: convertDateToDateTimeOffset(startDate),
          periodEndDate: convertDateToDateTimeOffset(endDate),
        });
      }
      break;
    default:
      // Error invalid choice.
      throw new Error('Invalid period choice.');
  }
}


// Helper utility to take the generic graph title and add the period to use text to it.  
export function reportTitleAddPeriodToUseText(theGraphTitle, periodToUse) {
  switch (parseInt(periodToUse)) {
    case REPORT_ON_PERIOD_1WEEK:
      theGraphTitle = theGraphTitle + " (Last week)";
      break;
    case REPORT_ON_PERIOD_30DAYS:
      theGraphTitle = theGraphTitle + " (Last 30 days)";
      break;
    case REPORT_ON_PERIOD_90DAYS:
      theGraphTitle = theGraphTitle + " (Last 3 months)";
      break;
    case REPORT_ON_PERIOD_180DAYS:
      theGraphTitle = theGraphTitle + " (Last 6 months)";
      break;
    case REPORT_ON_PERIOD_1YEAR:
      theGraphTitle = theGraphTitle + " (Last year)";
      break;
  }
  return theGraphTitle;
}


