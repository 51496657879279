import React from "react";
import Typography from '@mui/material/Typography';

// Component to display common section title and description.
// If description is passed in as an empty string, it will not display a SectionDescription.  Else it will display the description and include line breaks where necessary.
// We use conditional rendering statement using the logical AND (&&) operator to check if descriptionLength is greater than 0.
// We also use a React Fragment to wrap and display the mixed elements of br's with the section description.
const DisplaySectionTitleAndDescription = (props) => {
    
    const { sectionTitle, sectionDescription, isAdminPage } = props;        
    const descriptionLength = sectionDescription.length;

    // The section title color will change if we are an Admin Page - to tell the Admin User via identification of the color of the section title.
    // Admin page title will be "red".  Otherwise no background will be set for the section title.
    const sectionTitleStyle = {
        backgroundColor: isAdminPage ? 'lightcoral' : 'inherit',
        borderRadius: isAdminPage ? '10px' : 'inherit',
        padding: isAdminPage ? 8 : 'inherit',
      };
        
    return (                
        <div>
            <br />
            <Typography variant="h6" component="h6" style={sectionTitleStyle}>{ sectionTitle }</Typography>                               
                <br />
                {descriptionLength > 0 && (
                    <React.Fragment>                                                   
                    {sectionDescription}            
                    <br /><br /> 
                    </React.Fragment>
                )}                      
        </div>        
      );
 };

 export default DisplaySectionTitleAndDescription;