// React Components
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

// Material UI Components
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// Audit Vault Components
import signInLogo from '../imgs/buttonSignIn.svg';
import { loginToMicrosoft } from '../utilities/common-user-utils';


function MobileMenu(props) {

  const { user, isAuthenticated, userRole } = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  const { instance, accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openMenu2, setOpenMenu2] = React.useState(false);  // Menu 2 - Company.
  const [openMenu3, setOpenMenu3] = React.useState(false);  // Menu 3 - Tenant.
  const [openMenu4, setOpenMenu4] = React.useState(false);  // Menu 4 - Reports.
  const [openMenu5, setOpenMenu5] = React.useState(false);  // Menu 5 - Support.
  const [openMenu6, setOpenMenu6] = React.useState(false);  // Menu 6 - Admin.

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.location.href = "/myprofile";
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") 
    {
       instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        // empties the user object from App.js.  Controls what users can / cannot see in the menu
        setUser(''); 
    }
  }

  const handleClickMenu2 = () => {
    setOpenMenu2(!openMenu2);
  };

  const handleClickMenu3 = () => {
    setOpenMenu3(!openMenu3);
  };

  const handleClickMenu4 = () => {
    setOpenMenu4(!openMenu4);
  };

  const handleClickMenu5 = () => {
    setOpenMenu5(!openMenu5);
  };

  const handleClickMenu6 = () => {
    setOpenMenu6(!openMenu6);
  };

  return (
    <>
      <AppBar sx={{ display: 'flex' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon onClick={() => setOpenDrawer(!openDrawer)} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Audit Vault for M365
          </Typography>
          {isAuthenticated ? (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={() => handleLogout("redirect")}>Sign Out</MenuItem>
          </Menu>
        </div>
      ) : 
        <Button onClick={() => loginToMicrosoft(instance)} sx={{padding: '2px', '&:hover': { backgroundColor: 'lightblue' } }}>
          <img src={signInLogo} alt="Sign in with Microsoft" />
        </Button>
      }
        </Toolbar>
      </AppBar>
   
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
        <ListItem key="Home" onClick={() => setOpenDrawer(false)} disablePadding>
            <ListItemButton component={Link} to="/">
                <ListItemText primary="Home" />
            </ListItemButton>
        </ListItem>

          {(() => {

        // Company Admin Access Only
        if ((isAuthenticated && userRole <= 1 && userRole > 0 ) || (user.isSystemAdmin && user.companyId > 0)) {
        return(
        <>
        <ListItem key="Company" disablePadding>
            <ListItemButton component={Link} to="/MyCompany" onClick={handleClickMenu2}>
                <ListItemText primary="Company" />
            </ListItemButton>
        </ListItem>
        <Collapse in={openMenu2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/mycompany-roles">
                <ListItemText primary="Company Roles" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/mycompany-users">
                <ListItemText primary="Company Users" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/mybilling">
                <ListItemText primary="Billing" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/mycompany/user-activity-logs">
                <ListItemText primary="User Activity" />
            </ListItemButton>
            </List>
        </Collapse>
        </>
        )
        }
        return null;
        })()}

        {(() => {

        // Tenant Admin Access Only
        if ((isAuthenticated && userRole <= 2  && userRole > 0 ) || (user.isSystemAdmin && user.companyId > 0)) {
        return(
        <>
        <ListItem key="Tenant" disablePadding>
            <ListItemButton component={Link} to="/Tenant/MyTenant?tab=0" onClick={handleClickMenu3}>
                <ListItemText primary="Tenant" />
            </ListItemButton>
        </ListItem>
        <Collapse in={openMenu3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=1">
                <ListItemText primary="Roles" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=2">
                <ListItemText primary="SharePoint App" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=3">
                <ListItemText primary="Jobs" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Tenant/MyTenant?tab=4">
                <ListItemText primary="History" />
            </ListItemButton>
            </List>
        </Collapse>
        </>
        )
        }
        return null;

        })()}


        {(() => {

        // Tenant Report Viewer Access Only
        if ((isAuthenticated && userRole <= 3  && userRole > 0 ) || (user.isSystemAdmin && user.companyId > 0)) {
        return(<>
        <ListItem key="Reports" disablePadding>
        <ListItemButton component={Link} to="/Reports/ReportsHome" onClick={handleClickMenu4}>
            <ListItemText primary="Reports" />
        </ListItemButton>
        </ListItem>
        <Collapse in={openMenu4} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/SharePoint">
                <ListItemText primary="SharePoint" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/Exchange">
                <ListItemText primary="Exchange" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/EntraID">
                <ListItemText primary="Entra ID" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/teams">
                <ListItemText primary="Teams" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/InsightsSearch/All">
                <ListItemText primary="Insights Search" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Reports/ThreatIntelligence">
                <ListItemText primary="Detect Threats" />
            </ListItemButton>
            </List>
        </Collapse>
        </>
        )
        }
        return null;
        })()}

        {(() => {

        // Tenant Viewer or anyone who is logged in
        if (isAuthenticated) {
        return(
        <>
        <ListItem key="Support" disablePadding>
        <ListItemButton component={Link} to="/Support/SupportHome" onClick={handleClickMenu5}>
            <ListItemText primary="Support" />
        </ListItemButton>
        </ListItem>
        <Collapse in={openMenu5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>                
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/MessageCenter">
                <ListItemText primary="Message Center" />                    
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/ProductFeedback">
                <ListItemText primary="Product Feedback" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Support/SupportTicket">
                <ListItemText primary="Support Ticket" />
            </ListItemButton>                
            </List>
        </Collapse>
        </>
        )
        }
        return null;
        })()}

        {(() => {

        // System Admin Access Only
        if (isAuthenticated && user.isSystemAdmin) {
        return(
        <>
        <ListItem key="Admin" disablePadding>
        <ListItemButton component={Link} to="/Admin/AdminHome" onClick={handleClickMenu6}>
            <ListItemText primary="Admin" />
        </ListItemButton>
        </ListItem>
        <Collapse in={openMenu6} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>                
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Admin/CompanyListAdmin">
                <ListItemText primary="Company List" />                    
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} component={Link} to="/Admin/MessageCenterAdmin">
                <ListItemText primary="Message Center" />
            </ListItemButton>
            </List>
        </Collapse>
        </>
        )
        }
        return null;
        })()}

        </List>
      </Drawer>
      
    </>
  );
}
export default MobileMenu;