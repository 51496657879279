// React Components
import React from "react";
import { useEffect } from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


// Function to insert our Workload selector (textfield).
export default function WorkloadSelector(props){

    const { tenantId, selectedWorkloads, setSelectedWorkloads, defaultWorkload, errorSelectedWorkloads } = props;

    const workloads = [
        'All',
        'SharePoint',
        'Exchange',
        'Entra ID',
        'Teams',
      ];
      
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
        },
    };

    // Use useEffect to set the default value when defaultWorkload changes
    useEffect(() => {
        if (defaultWorkload && workloads.includes(defaultWorkload)) {
            // Make sure the defaultWorkload is a valid workload option
            setSelectedWorkloads([defaultWorkload]);
        }
    }, [defaultWorkload, setSelectedWorkloads]);


    return(
        
        <Grid item xs={12}>
            {/* Workload input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>Workload:</b>
                </div>
                <div>
                <FormControl variant="outlined" error={errorSelectedWorkloads}>
                <Select
                labelId="workload-multiple-checkbox-label"
                id="workload-multiple-checkbox"
                multiple
                value={selectedWorkloads}
                onChange={event => setSelectedWorkloads(event.target.value)}                
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                style={{ width: 398 }}    
                //error={errorSelectedWorkloads}
                //helperText={errorSelectedWorkloads ? 'You must select at least 1 workload.' : ''}  
                >
                {workloads.map((workload) => (
                    <MenuItem key={workload} value={workload}>
                    <Checkbox checked={selectedWorkloads.indexOf(workload) > -1} />
                    <ListItemText primary={workload} />
                    </MenuItem>
                ))}
                </Select>
                <FormHelperText>{errorSelectedWorkloads ? 'You must select at least 1 workload.' : ''}</FormHelperText>
                </FormControl>
                </div>
            </div>
        </Grid>
    );
};