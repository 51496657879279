// React Components
import * as React from 'react';

// Material UI Components

// Audit Vault Components
import ReportTemplateActivityByTenantAndPeriodWithLineChart from './ReportTemplateActivityByTenantAndPeriodWithLineChart';

// Audit Vault Utilities


/*
Report returns the unique counts for EntraID Failed logins over a set period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- reportOnDate: The date from which the report is generated on (ex. today or yesterday, or 1 year ago). 
- displayDataSource: 0 or 1.  (if true displays table of sortable source data, if false includes link to report to view full details).
- datasetFieldToUse: ex.) operation, userId 
    The datasetVariableFieldName is used to generate unique line data.  
    Change this to "operation", "userId" or any other desired variable.  
    NOTE: this field below will be used to generate unique lines from the dataset.  
    The field must exist in the dataset.  Please use caution if choosing a field that has many unique values.
*/
export default function EntraIDFailedLogins(props) {

    // Component Constants
    const { user, companyId, tenantId, periodToUse, loadData, filterByColumn} = props;        
    var theGraphTitle = "Entra ID Failed Logins";        

    // For the optional table to display data source.  815 px to work with.    
    const columns = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth:40, align: 'left' },        
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
        { id: 'userType', label: 'User Type', minWidth: 40, maxWidth: 40, align: 'left' },        
        { id: 'resultStatus', label: 'Result Status', minWidth: 50, maxWidth:60, align: 'left' },        
        { id: 'actorIpAddress', label: 'IP Address', minWidth: 40, maxWidth: 85, align: 'left' },        
        { id: 'applicationId', label: 'Application ID', minWidth: 40, maxWidth: 115, align: 'left' },        
        { id: 'deviceProperties', label: 'Device Properties', minWidth: 50, maxWidth: 100, align: 'left' }
    ];    
    
    // Component Functions

    // Component UI.
    return (
        <>        
            <ReportTemplateActivityByTenantAndPeriodWithLineChart 
                user={user}
                companyId={companyId}
                tenantId={tenantId} 
                periodToUse={periodToUse} 
                theGraphTitle={theGraphTitle} 
                reportType={"EntraIDFailedLogins"}
                columns={columns}
                loadData={loadData}
                filterByColumn={filterByColumn} />
        </>
    );    
}
