import React from "react";
import { useNavigate } from "react-router-dom";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';


const AccessDenied = () => {
  
      const navigate = useNavigate();

      return (
        <>
        <Alert severity="error">
          <AlertTitle>Access Denied - you do not have the necessary permissions or the Tenant you are accessing is invalid.</AlertTitle>
          If you believe you are getting this message in error, please contact your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Company Administrator</Link>.
        </Alert>
      </>);
      
}
export default AccessDenied;