// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const TenantSetupRequired = (props) => {
  
  return (
    <Alert severity="error">
        <AlertTitle>Complete Tenant Setup</AlertTitle>
        This section will be available once your tenant has run its first successful audit import.
    </Alert>
  );
}

export default TenantSetupRequired;
