// React Components
import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";

// Material UI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid'; 
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { formatDate } from '../../utilities/common-date-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const rows = [];

const columns = [
    { id: 'id', label: 'Id', minWidth: 10, align: 'left' },
    { id: 'reportTypeId', label: 'Scheduled Report', minWidth: 50,  align: 'left' },
    { id: 'scanPeriodInHours', label: 'Scan Period', minWidth: 20, align: 'left' },
    { id: 'thresholdCount', label: 'Threshold', minWidth: 20, align: 'left' },
    { id: 'lastSuccessfulRunTime', label: 'Last Successful Run', minWidth: 50, align: 'left'},
    { id: 'details', label: 'Details', minWidth: 10, align: 'right'},
  ];


export default function ScheduledThreatJobList(props) {

  const { user, tenant } = props;

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);

  const navigate = useNavigate();

  // Need page variables for each audit type
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sortConfig, setSortConfig] = useState(null); 

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};

function getReportType(val)
{

  switch (val)
  {
    case 0:
      return 'Failed Logins Due to Failed MFA';

    case 1:
      return 'Failed Logins Due to Invalid Password';

    case 2:
      return 'Successful Logins with Different IPs';

    case 3:
      return 'Bulk SharePoint File Downloads';

    case 4:
      return 'Bulk SharePoint File Accesses';

    case 5:
      return 'Bulk SharePoint File or Folder Deletions';

    case 6:
      return 'Bulk SharePoint Permissions Updates';

    case 7:
      return 'Bulk Exchange Deleted Items/Emails';
  }

  return 'Unknown';

}

function getScanPeriod(val)
{
  switch (val)
  {
    case 24:
      return '1 Day';

    case 168:
      return '1 Week';

    case 336:
      return '2 Weeks';

    case 504:
      return '3 Weeks';

    case 672:
      return '4 Weeks';
  }

  return 'Unknown';
 
}

 // Function for Sorting column headers.  
 function stableSort(array, comparator) {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Function for Sorting column headers.
function getComparator(sortConfig) {
  return (a, b) => {
      if (!sortConfig) {
          return 0;
      }
      if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [jobDetails, setJobDetails] = React.useState("");
  const [loadingRow, setLoadingRow] = useState(false);

  const handleOpen = async (val, jobId) => {

    setOpen(val);

    if (val) {
        setLoadingRow(true);
        try 
        {

          
        }
        catch (e) {
            console.log("ERROR: handleOpen");
            console.log(e);
        }
        finally {
            setLoadingRow(false);
        }
      }

     
  }

  return (
    <React.Fragment>        
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell key={row.id + "-scheduleId"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.id}
            </TableCell>
            <TableCell key={row.id + "-reportType"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {getReportType(row.reportTypeId)}
            </TableCell>
            <TableCell key={row.id + "-scanPeriod"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {getScanPeriod(row.scanPeriodInHours)}
            </TableCell>
            <TableCell key={row.id + "-threshold"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.threasholdCount}
            </TableCell>
            <TableCell key={row.id + "-lastRunTime"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.lastSuccessfulRunTime ? formatDate(row.lastSuccessfulRunTime) : 'N/A'}
            </TableCell>
            <TableCell key={row.id + "-seeDetails"} sx={{textAlign : 'right'}}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleOpen(!open, row)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell key={row.id + "-rowDetails"} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography>Was Last Run Successful:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              {row.lastRunSuccessful &&
                                  <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                              }
                              {!row.lastRunSuccessful &&
                                  <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                              }
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Schedule:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              {row.runMon &&
                                <Typography>Mondays</Typography>
                              }
                              {row.runTue &&
                                <Typography>Tuesdays</Typography>
                              }
                              {row.runWed &&
                                <Typography>Wednesdays</Typography>
                              }
                              {row.runThu &&
                                <Typography>Thursdays</Typography>
                              }
                              {row.runFri &&
                                <Typography>Fridays</Typography>
                              }
                              {row.runSat &&
                                <Typography>Saturdays</Typography>
                              }
                              {row.runSun &&
                                <Typography>Sundays</Typography>
                              }
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Run Start Time:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              {row.runStartTime}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Send Emails Only on Threat:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              {row.sendEmailOnlyWhenThreat && 'Yes'}
                              {!row.sendEmailOnlyWhenThreat && 'No'}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>Is Enabled:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              {row.isActive && 'Yes'}
                              {!row.isActive && 'No'}
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'right'}}>
                              <Button size="small" onClick={() => navigate(`/Reports/ThreatIntelligence/ScheduleReport/${tenant.id}/${row.id}`)}>Edit</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment >
);
}


  
async function fetchJobsData() {
  try {
      if (tenant) {

        var usrEmail = "N/A";
        if (user.userEmail)
            usrEmail = user.userEmail;

        var payload = `{
            companyId: ${tenant.companyId},
            tenantId: ${tenant.id},
            userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
            userEmail: "${await encryptKey(usrEmail)}"
        }`;
            
        if (payload) {
            var result = await axios.get(`${WEB_API_URL}ReportThreat/GetScheduledThreatReportJobsByTenantId`, {
                params: {
                    key: `${payload}`
                }
            });
            if (result.data != null) {
              setJobs(result.data);
            }
        }
      }
  }
  catch (e) {
    console.log("ERROR: ScheduledThreatJobsList.fetchJobsData")
    console.log(e);
    setServiceIsDownError(true);
  }
  finally{
    setLoading(false);
  }
}

useEffect(() => {
    fetchJobsData();
}, []);


  // Component UI
  if (loading) {
    return (<><ProgressBar message="Loading ..." loading={loading} /></>);
  }

  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  return (
    <><Card sx={{ width: '100%' }} key="jobsList" variant="outlined">
      <CardContent>
      
      Below is a list of your scheduled threat detection reports and status.<br /><br />
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }
              }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs && stableSort(jobs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((job) => (
              <Row key={job.id} row={job} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="span"
        count={jobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </CardContent>
      </Card>
    </>
  );
}
