// React Components
import * as React from 'react';

// Material UI Components

// Audit Vault Components
import ReportTemplateActivityByTenantAndPeriodWithLineChart from './ReportTemplateActivityByTenantAndPeriodWithLineChart';

// Audit Vault Utilities


/*
Report returns the unique counts for EntraID Group and User Activity over a set period.
Parameters:
- tenantId: The Tenant Id we are reporting on.
- periodToUse: The reporting periods to report on (data points).
- reportOnDate: The date from which the report is generated on (ex. today or yesterday, or 1 year ago). 
- displayDataSource: 0 or 1.  (if true displays table of sortable source data, if false includes link to report to view full details).
*/
export default function EntraIDGroupUserActivity(props) {

    // Component Constants
    const { user, companyId, tenantId, periodToUse, loadData, filterByColumn } = props;        
    var theGraphTitle = "Entra ID Group and User Activity";        

    // For the optional table to display data source.  815 px to work with.    
    const columns = [
        { id: 'id', label: 'Id', minWidth: 30, maxWidth:40, align: 'left' },        
        { id: 'operation', label: 'Event', minWidth: 50, maxWidth: 110, align: 'left' },        
        { id: 'creationTime', label: 'Date', minWidth: 50, maxWidth: 105, align: 'left' },        
        { id: 'userId', label: 'User', minWidth: 50, maxWidth: 160, align: 'left' },
        { id: 'objectId', label: 'ObjectId', minWidth: 50, maxWidth:100, align: 'left' },             
        { id: 'modifiedProperties', label: 'Modified Properties', minWidth: 50, maxWidth: 100, align: 'left' },        
        { id: 'actor', label: 'Actor', minWidth: 50, maxWidth: 100, align: 'left' },        
        { id: 'target', label: 'Target', minWidth: 50, maxWidth: 100, align: 'left' },        
    ];    
    


    // Component Functions

    // Component UI.
    return (
        <>        
            <ReportTemplateActivityByTenantAndPeriodWithLineChart 
                 user={user}
                 companyId={companyId}
                 tenantId={tenantId} 
                 periodToUse={periodToUse} 
                 theGraphTitle={theGraphTitle} 
                 reportType={"EntraIDUserGroupActivity"}
                 columns={columns}
                 loadData={loadData}
                 filterByColumn={filterByColumn} />
        </>
    );    


}
