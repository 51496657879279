// React Components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PolicyIcon from '@mui/icons-material/Policy';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from "../components/common/TrialPeriodNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import TenantDropDown from "../components/reports/TenantDropDown";
import AuditsByWorkloadSource from "../components/reports/AuditsByWorkloadSource";
import TenantSetupRequired from "../components/tenant/TenantSetupRequired";
import ScheduledThreatJobList from "../components/reports/ScheduledThreatJobsList";
import ScheduledThreatJobHistoryList from "../components/reports/ScheduledThreatJobHistoryList";

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { formatAuditJobRunTimeDateServerTimeToGMT } from '../utilities/common-date-utils';
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTADMINISTRATOR, ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";

const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Threat Intelligence', link: '/Reports/ThreatIntelligence' },
];

const theSectionTitle = "Threat Intelligence (Detect Threats) Dashboard";
const theSectionDesc = "Harness cloud-based threat detection heuristics for proactive security monitoring to help you identify security breaches, incidents, concerns and enable risk mitigation in your M365 environment.  Below are built-in reports that you can use to assist in detecting both common external and internal threats.";
const isAdminPage = false;


function ReportsThreatIntelligence(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;
    const queryParams = new URLSearchParams(window.location.search);

    // Component Constants
    const [company, setCompany] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [focusTenantId, setFocusTenantId] = useState("");
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const [tabValue, setTabValue] = useState(0);

    // Component Functions
    // Load TenantHealthStats given the tenandId.
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.
            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER);
            if (!user || !checkReportAccessForTenant) {
                setAccessDenied(true);
            }
            else {
                setAccessDenied(false);
            }

            // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
            var response2 = await getHealthStatsByTenantId(theTenantId, 8, user);
            if (response2) {
                setTenantHealthStats(response2);
            }

        }
        catch (e) {
            console.log("ERROR: ReportThreatIntelligence.fetchTenantHealthStats");
            console.log(e)
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchCompanyData() {

            try {
                if (queryParams.get("tab") && queryParams.get("tab").length > 0) {
                    setTabValue(Number(queryParams.get("tab")));
                }

                if (user && user.companyId) {
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {

                        setCompany(response1);
                        setSelectedTenantOption(response1.tenantList[0]);

                        if (response1.tenantList && response1.tenantList.length > 0) {
                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                        }
                    }

                }
                setLoading(false);
            }
            catch (e) {
                console.log("ERROR: ReportThreatIntelligence.fetchCompanyData");
                console.log(e)
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

        fetchCompanyData();
    }, []);


    // Tab panel.
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        value: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    // Event handlers for drop down lists.
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
    };


    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    // Get the total audit record count.
    var totalSharePointAuditRecords = 0;
    var totalExchangeAuditRecords = 0;
    var totalAzureAdAuditRecords = 0;
    var totalTeamsAuditRecords = 0;
    if (tenantHealthStats.totalSharePointAuditRecords) {
        totalSharePointAuditRecords = tenantHealthStats.totalSharePointAuditRecords;
    }
    if (tenantHealthStats.totalExchangeAuditRecords) {
        totalExchangeAuditRecords = tenantHealthStats.totalExchangeAuditRecords;
    }
    if (tenantHealthStats.totalEntraIDAuditRecords) {
        totalAzureAdAuditRecords = tenantHealthStats.totalEntraIDAuditRecords;
    }
    if (tenantHealthStats.totalTeamsAuditRecords) {
        totalTeamsAuditRecords = tenantHealthStats.totalTeamsAuditRecords;
    }
    var totalAuditRecords = totalSharePointAuditRecords + totalExchangeAuditRecords + totalAzureAdAuditRecords + totalTeamsAuditRecords;

    // A valid Tenant is chosen from the textfield selection drop down list.
    return (
        <>
            <TrialPeriodNag company={company}></TrialPeriodNag>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            { /* Display report section icon logo. */}
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><PolicyIcon style={{ fontSize: '3rem' }} />
                </div>
            </div>
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

            <div>
                <Grid container spacing={2}>
                    {
                        accessDenied &&
                        <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}><AccessDenied /></Grid>
                    }
                    {!selectedTenantOption &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>Your Company has not yet completed the setup process or you have not yet specified a valid Tenant.</AlertTitle>
                                </Alert>
                            </Grid>)
                    }
                    {tenantHealthStats.status == 0 &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <TenantSetupRequired />
                            </Grid>
                        )
                    }
                    {selectedTenantOption &&
                        (
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <TenantDropDown
                                        user={user}
                                        company={company}
                                        handleTenantOptionChange={handleTenantOptionChange}
                                        selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                </Grid>
                            </Grid>
                        )
                    }

                    {!accessDenied && selectedTenantOption && tenantHealthStats.status >= 1 ? (
                        <>

                            { /* Display last audit job run time. */}
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                Audit data last updated on: {formatAuditJobRunTimeDateServerTimeToGMT(tenantHealthStats.entraIDAuditLastSuccessfulRunTime)}
                                <br />
                            </Grid>

                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', minWidth: 320 }}>
                                    <AuditsByWorkloadSource totalSharePointAuditRecords={totalSharePointAuditRecords} totalExchangeAuditRecords={totalExchangeAuditRecords} totalAzureAdAuditRecords={totalAzureAdAuditRecords} totalTeamsAuditRecords={totalTeamsAuditRecords} />
                                </Paper>
                            </Grid>
                            { /* Display total audit record count. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', minWidth: 200, maxwidth: 220 }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Total Audit Records</Typography>
                                        <br /><br />
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography component="div" variant="h5" sx={{ fontSize: 19, fontWeight: '800' }}>{totalAuditRecords}</Typography>
                                        </div>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            { /* Display a link to Insights Search. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Search your Audit Records</Typography>
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                                            <Link onClick={() => navigate(`/Reports/InsightsSearch/All`)} component="button">Configure and run an Insights Search.</Link>
                                        </div>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Exchange Reports Tab">
                                            <Tab label="Detect" {...a11yProps(0)} />
                                            <Tab label="Schedule" {...a11yProps(1)} />
                                            <Tab label="Monitor" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>

                                        Report List: External Threats<br />
                                        <Link onClick={() => navigate(`/Reports/ThreatEntraIDFailedLoginsInvalidPasswordReport/${focusTenantId}`)} component="button">Failed Logins due to Invalid Password</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatEntraIDFailedLoginsFailedMFAReport/${focusTenantId}`)} component="button">Failed Logins due to MFA Related Failure</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatEntraIDSuccessfulLoginsDifferentIPsReport/${focusTenantId}`)} component="button">Flag Successful Logins with Different IP Addresses (unusual activity)</Link><br />
                                        <br />
                                        Report List: Insider Threats (potential malicious activity)<br />
                                        <Link onClick={() => navigate(`/Reports/ThreatSharepointFileDownloadsReport/${focusTenantId}`)} component="button">Identify Bulk SharePoint File Downloads</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatSharepointFileAccessesReport/${focusTenantId}`)} component="button">Identify Bulk SharePoint File Accesses</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatSharepointFileOrFolderDeletionsReport/${focusTenantId}`)} component="button">Identify Bulk SharePoint File or Folder Deletions</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatSharepointPermsUpdateAddedToGroupReport/${focusTenantId}`)} component="button">Identify Bulk SharePoint Permissions Updates (AddToGroup)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/ThreatExchangeDeletedItemsReport/${focusTenantId}`)} component="button">Identify Bulk Exchange Deleted Items</Link><br />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        {verifyUserHasTenantRolePermissions(user, selectedTenantOption, ROLE_CODE_TENANTADMINISTRATOR) ?
                                            <><ScheduledThreatJobList user={user} tenant={selectedTenantOption} /><br></br><Button
                                                variant="contained"
                                                type="button"
                                                size='small'
                                                onClick={() => navigate(`/Reports/ThreatIntelligence/ScheduleReport/${focusTenantId}`)}
                                            >
                                                Schedule New Threat Report
                                            </Button></>
                                            :
                                            <><AccessDenied /></>
                                        }


                                    </TabPanel>
                                    <TabPanel value={tabValue} index={2}>
                                        {verifyUserHasTenantRolePermissions(user, selectedTenantOption, ROLE_CODE_TENANTADMINISTRATOR) ?
                                            <ScheduledThreatJobHistoryList user={user} tenant={selectedTenantOption} />
                                            :
                                            <><AccessDenied /></>
                                        }
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </>
                    ) :
                        (
                            <>
                                <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                    <ProgressBar message="Loading ..." loading={true} />
                                </Grid>
                            </>
                        )
                    }
                    <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                        <br />
                        Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        <br />* Unless otherwise specified as UTC, graphs on page are using your browser's local timezone.
                    </Grid>
                </Grid>
            </div>
        </>)
}
export default ReportsThreatIntelligence;