const SYSEVENT_LOGIN = "Login";
const SYSEVENT_VIEW_SP_AUDITHISTORY_REPORT = "ViewReport-SharePointAuditHistory";
const SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT = "ViewReport-SPOSharePointAuditHistory";
const SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT_NOACCESS = "ViewReport-SPOSharePointAuditHistory-AccessDenied";

export {
    SYSEVENT_LOGIN,
    SYSEVENT_VIEW_SP_AUDITHISTORY_REPORT,
    SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT,
    SYSEVENT_VIEW_SP_SPOAUDITHISTORY_REPORT_NOACCESS
};