// React Components
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';


// Function to insert our User selector (textfield).
// Later enhance to auto-complete on user name.
export default function UserSelector(props){

    const { tenantId, selectedUserId, setSelectedUserId, selectedUserIdValue, errorSelectedUserId } = props;

    return(
        
        <Grid item xs={12}>
            {/* User input field control. */}
            <div style={{ display: 'flex' }}>
                <div style={{ width: '140px', display: 'flex', alignItems: 'center' }}>
                    <b>For User:</b>
                </div>
                <div>
                    <TextField                        
                        required
                        name="User Text Field"
                        id="userTextfield"
                        placeholder="Enter User Id here (firstname.lastname@company.com)."
                        variant="outlined"
                        size="small"
                        style={{ width: 600 }}
                        inputProps={{ maxLength: 100 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => setSelectedUserId(event.target.value)}
                        inputRef={selectedUserIdValue}
                        value={selectedUserId}
                        error={errorSelectedUserId}
                        helperText={errorSelectedUserId ? 'User ID cannot be empty.' : ''}                     
                    />
                </div>
            </div>
        </Grid>
    );
};