// React Components
import * as React from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

// Material UI Components
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


// Audit Vault Components
import signInLogo from '../imgs/buttonSignIn.svg';
import { loginToMicrosoft } from '../utilities/common-user-utils';


export default function MainAppBar(props) {

  const { setUser } = props;

  const { instance, accounts } = useMsal();
  const drawerWidth = 240;
  const isAuthenticated = useIsAuthenticated();
  const name = accounts[0] && accounts[0].name;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.location.href = "/myprofile";
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") 
    {
       instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        // empties the user object from App.js.  Controls what users can / cannot see in the menu
        setUser(''); 
    }
}

  return (
    <AppBar
    position="absolute"
    sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth + 500}px`, minWidth: 1024 }}>
    <Toolbar>
    <Typography variant="h6" component="span" sx={{width: '80%'}}>
      Audit Vault for M365
    </Typography>
    <Typography component="span" sx={{ minWidth: 200, width:'20%', textAlign: 'right'}}>
    {isAuthenticated ? (
        <div>
          {name}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={() => handleLogout("redirect")}>Sign Out</MenuItem>
          </Menu>
        </div>
      ) : 
        <Button onClick={() => loginToMicrosoft(instance)} sx={{padding: '2px', '&:hover': { backgroundColor: 'lightblue' } }}>
          <img src={signInLogo} alt="Sign in with Microsoft" />
        </Button>
      }
    </Typography>
    </Toolbar>
    </AppBar>
  );
}