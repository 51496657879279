export const getTenantStatus = (status) => {
    if (status == 3)
        return "Critical";
    if (status == 2)
        return "Warnings";
    if (status == 1)
        return "Healthy";
    if (status == 0)
        return "Pending Validation";
};

export const getTenantStatusColour = (status) => {
    if (status == 3)
        return "red";
    if (status == 2)
        return "orange";
    if (status == 1)
        return "green";
    if (status == 0)
        return "blue";
};