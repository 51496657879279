// React Components
import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TablePagination from '@mui/material/TablePagination';

// Audit Vault Components
import ProgressBar from '../common/ProgressBar';
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { formatDate } from '../../utilities/common-date-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';

const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

const rows = [];

const columns = [
    { id: 'isLastRunSuccessful', label: 'Successful', minWidth: 10, align: 'left' },
    { id: 'reportTypeId', label: 'Scheduled Report', minWidth: 100,  align: 'left' },
    { id: 'lastSuccessfulRunEndTime', label: 'Last Successful Run', minWidth: 50, align: 'left'},
    { id: 'emailSent', label: 'Email Sent', minWidth: 20, align: 'left' },
    { id: 'emailRecipients', label: 'Email Recipients', minWidth: 100, align: 'left' },
    { id: 'threatDetected', label: 'Threat Detected', minWidth: 20, align: 'right' },
  ];


export default function ScheduledThreatJobHistoryList(props) {

  const { user, tenant } = props;

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [serviceIsDownError, setServiceIsDownError] = useState(false);

  const navigate = useNavigate();

  // Need page variables for each audit type
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sortConfig, setSortConfig] = useState(null); 

const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
};

function getReportType(val)
{

  switch (val)
  {
    case 0:
      return 'Failed Logins Due to Failed MFA';

    case 1:
      return 'Failed Logins Due to Invalid Password';

    case 2:
      return 'Successful Logins with Different IPs';

    case 3:
      return 'Bulk SharePoint File Downloads';

    case 4:
      return 'Bulk SharePoint File Accesses';

    case 5:
      return 'Bulk SharePoint File or Folder Deletions';

    case 6:
      return 'Bulk SharePoint Permissions Updates';

    case 7:
      return 'Bulk Exchange Deleted Items/Emails';
  }

  return 'Unknown';

}


 // Function for Sorting column headers.  
 function stableSort(array, comparator) {

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Function for Sorting column headers.
function getComparator(sortConfig) {
  return (a, b) => {
      if (!sortConfig) {
          return 0;
      }
      if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [jobDetails, setJobDetails] = React.useState("");
  const [loadingRow, setLoadingRow] = useState(false);

  const handleOpen = async (val, jobId) => {

    setOpen(val);

    if (val) {
        setLoadingRow(true);
        try 
        {

          
        }
        catch (e) {
            console.log("ERROR: handleOpen");
            console.log(e);
        }
        finally {
            setLoadingRow(false);
        }
      }

  }

  return (
    <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell key={row.id + "-successful"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.isLastRunSuccessful &&
                    <TaskAltIcon fontSize="small" sx={{ color: "green" }}></TaskAltIcon>
                }
                {!row.isLastRunSuccessful &&
                    <RadioButtonUncheckedIcon fontSize="small" sx={{ color: "red" }}></RadioButtonUncheckedIcon>
                }
            </TableCell>
            <TableCell key={row.id + "-reportType"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {getReportType(row.reportTypeId)}
            </TableCell>
            <TableCell key={row.id + "-lastRunTime"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.lastSuccessfulRunEndTime ? formatDate(row.lastSuccessfulRunEndTime) : 'N/A'}
            </TableCell>
            <TableCell key={row.id + "-emailSent"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.emailSent && 'Yes'}
                {!row.emailSent && 'No'}
            </TableCell>
            <TableCell key={row.id + "-emailRecipients"} component="th" scope="row" sx={{textAlign : 'left'}}>
                {row.emailRecipients}
            </TableCell>
            <TableCell key={row.id + "-threatDetected"} component="th" scope="row" sx={{textAlign : 'center'}}>
                {row.threatDetected && <><strong style={{color: 'red'}}>Yes</strong></>}
                {!row.threatDetected && 'No'}
            </TableCell>
        </TableRow>
    </React.Fragment>
);
}


  
async function fetchJobsHistoryData() {
  try {
      if (tenant) {

        var usrEmail = "N/A";
        if (user.userEmail)
            usrEmail = user.userEmail;

        var payload = `{
            companyId: ${tenant.companyId},
            tenantId: ${tenant.id},
            userMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
            userEmail: "${await encryptKey(usrEmail)}"
        }`;
            
        if (payload) {
            var result = await axios.get(`${WEB_API_URL}ReportThreat/GetLastMonthsScheduledThreatReportHistoryByTenantId`, {
                params: {
                    key: `${payload}`
                }
            });
            if (result.data != null) {
              setJobs(result.data);
            }
        }
      }
  }
  catch (e) {
    console.log("ERROR: ScheduledThreatJobHistoryList.fetchJobsHistoryData")
    console.log(e);
    setServiceIsDownError(true);
  }
  finally{
    setLoading(false);
  }
}

useEffect(() => {
    fetchJobsHistoryData();
}, []);


  // Component UI
  if (loading) {
    return (<><ProgressBar message="Loading ..." loading={loading} /></>);
  }

  if (serviceIsDownError) {
    return (<><ServiceIsDown></ServiceIsDown></>);
  }

  return (
    <><Card sx={{ width: '100%' }} key="jobsList" variant="outlined">
      <CardContent>
      Displays a list of your most recent scheduled threat detection report results.<br /><br />
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }
              }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs && stableSort(jobs, getComparator(sortConfig)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((job) => (
              <Row key={job.id} row={job} />
          ))}
          
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="span"
        count={jobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </CardContent>
      </Card>
    </>
  );
}
