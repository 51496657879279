import { JSEncrypt } from '../utilities/JSEncrypt';

// Audit Vault Constants
const PUBLIC_KEY = process.env.REACT_APP_RSA_PUBLICKEY;

export async function encryptKey(key) {

    var crypt = new JSEncrypt();
    crypt.setPublicKey(PUBLIC_KEY);

    var encryptedMsg = crypt.encrypt(key);

    return encryptedMsg;
}