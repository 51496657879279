// React Components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from "../components/common/TrialPeriodNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import TenantDBStats from "../components/TenantDBStats";
import AuditsByWorkloadSource from "../components/reports/AuditsByWorkloadSource";
import AuditGrowth from "../components/reports/AuditGrowth";
import SharePointFileActivity from "../components/reports/SharePointFileActivity";
import SharePointPageViews from "../components/reports/SharePointPageViews";
import ImageSharePoint from '../imgs/iconSharePoint.svg';
import ImageExchange from '../imgs/iconExchange.svg';
import ImageEntraID from '../imgs/iconEntraID.svg';
import TenantDropDown from "../components/reports/TenantDropDown";
import TenantSetupRequired from "../components/tenant/TenantSetupRequired";

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { NumberBadge } from '../utilities/common-ui-utils';
import { REPORT_ON_PERIOD_1WEEK } from "../constants/constants-reportperiods";
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";

/*
Displays an executive dashboard summary for Company X.
Takes in companyId as optional parameter.
Future to do: check if the User has access to Company.  If not display permission denied message.
*/
function Reports(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;

    // Component Constants
    const [company, setCompany] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();

    const [focusTenantId, setFocusTenantId] = useState("");

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Reports', link: '/Reports/ReportsHome' },
    ];

    const theSectionTitle = "Audit Vault for M365 - Executive Reports Dashboard";
    const theSectionDesc = "Your M365 audit logs are safely being managed by Audit Vault for M365.  This allows you to ensure that pro-active monitoring, compliance and security are enabled for your organization’s data.  You may perform passive monitoring, active event monitoring, track user and activity, perform security audits, and preserve and retrieve a complete history for documents and user activity to ensure complete compliance.";
    const isAdminPage = false;

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
    };


    // Component Functions
    // Load TenantHealthStats given the tenandId.
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.

            if (!verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER)) {
                setAccessDenied(true);
            }
            else {
                // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
                var response2 = await getHealthStatsByTenantId(theTenantId, 1, user);
                if (response2) {
                    setTenantHealthStats(response2);
                }
            }
        }
        catch (e) {
            console.log("ERROR: Report.fetchTenantHealthStats");
            console.log(e)
            if (user && user.id == 0) {
                setAccessDenied(true);
            }
            else
                setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        async function fetchCompanyData() {
            setLoading(true);
            try {
                if (user && user.companyId) {

                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {

                        setCompany(response1);
                        // Set the default to the first item in the list if there is any.
                        if (response1.tenantList && response1.tenantList.length > 0) {
                            setSelectedTenantOption(response1.tenantList[0]);  // Set to the first item in the list.

                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                        }
                    }
                }
            }
            catch (e) {
                console.log("ERROR: Report.fetchCompanyData");
                console.log(e)
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

        fetchCompanyData();

    }, []);
    // So at this point - the value for selectedTenantOption is either empty or the tenant object in question.  
    // We can use the selectedTenantOption as what we need to load the reports for (tenant object).  


    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    if (selectedTenantOption && tenantHealthStats.status >= 1) {
        // Load the Tenant stats from tenantHealthStats.

        var totalAuditRecords = tenantHealthStats.totalSharePointAuditRecords +
            tenantHealthStats.totalExchangeAuditRecords +
            tenantHealthStats.totalEntraIDAuditRecords +
            tenantHealthStats.totalTeamsAuditRecords;

        // A valid Tenant is chosen from the textfield selection drop down list.
        return (
            <>
                <TrialPeriodNag company={company}></TrialPeriodNag>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

                <TenantDropDown
                    user={user}
                    company={company}
                    handleTenantOptionChange={handleTenantOptionChange}
                    selectedTenantOption={selectedTenantOption}></TenantDropDown>
                <br />
                <div>
                    <Grid container spacing={2}>
                        {
                            accessDenied &&
                            <Grid><AccessDenied /></Grid>
                        }
                        {!accessDenied &&

                            <>
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <AuditsByWorkloadSource
                                            totalSharePointAuditRecords={tenantHealthStats.totalSharePointAuditRecords}
                                            totalExchangeAuditRecords={tenantHealthStats.totalExchangeAuditRecords}
                                            totalAzureAdAuditRecords={tenantHealthStats.totalEntraIDAuditRecords}
                                            totalTeamsAuditRecords={tenantHealthStats.totalTeamsAuditRecords} />
                                    </Paper>
                                </Grid>
                                { /* Display total audit record count. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <div>
                                            <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Total Audit Records</Typography>
                                            <br /><br />
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography component="div" variant="h5" sx={{ fontSize: 19, fontWeight: '800' }}>{totalAuditRecords}</Typography>
                                            </div>
                                        </div>
                                        <br />
                                    </Paper>
                                </Grid>
                                { /* Display audit counts in last 30 days by workload source. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Audit Records (Last 30 days)</Typography>
                                            <br />
                                            <TableContainer>
                                                <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2 }}>SharePoint</TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={tenantHealthStats.totalSharePointAuditRecordsIn30Days} bgcolor='#ff6384' txtcolor='white' /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2 }}>Exchange</TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={tenantHealthStats.totalExchangeAuditRecordsIn30Days} bgcolor='#36a2eb' txtcolor='white' /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2 }}>Entra ID</TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={tenantHealthStats.totalEntraIDAuditRecordsIn30Days} bgcolor='#ffcd56' txtcolor='white' /> </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2 }}>Teams</TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={tenantHealthStats.totalTeamsAuditRecordsIn30Days} bgcolor='#12D34A' txtcolor='white' /> </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        <br />
                                    </Paper>
                                </Grid>
                                { /* Display AuditGrowth Report. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <AuditGrowth tenantId={focusTenantId} periodToUse={REPORT_ON_PERIOD_1WEEK} reportType="All" />
                                        {/*<AuditGrowth tenantId={focusTenantId} periodToUse={REPORT_ON_PERIOD_1WEEK} reportOnDate={new Date(2023, 7, 4)} />*/}
                                    </Paper>
                                </Grid>


                                { /* Display the SharePoint Page Views Report. 

                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <SharePointPageViews
                                            user={user}
                                            companyId={company.id}
                                            tenantId={focusTenantId}
                                            periodToUse={REPORT_ON_PERIOD_1WEEK}
                                            loadData={false}
                                            filterByColumn={"Operation"} />
                                    </Paper>
                                </Grid>
                                */}

                                { /* Display Tenant Audit Storage Summary. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Tenant Audit Storage Summary</Typography>
                                        <TenantDBStats user={user} tenant={selectedTenantOption} viewFullSize={true} showTenantUserCount={false} />
                                    </Paper>
                                </Grid>

                                { /* Display the SharePoint File Activity Report. 

                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', overflowWrap: 'break-word', height: '100%' }}>
                                        <SharePointFileActivity user={user} companyId={company.id} tenantId={focusTenantId} periodToUse={REPORT_ON_PERIOD_1WEEK} loadData={false} reportOnDate={new Date()} displayDataSource={0} filterByColumn={"operation"} />
                                    </Paper>
                                </Grid>                                

                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', overflowWrap: 'break-word' }}>
                                        <SharePointFileActivity user={user} companyId={company.id} tenantId={focusTenantId} periodToUse={REPORT_ON_PERIOD_1WEEK} loadData={false} reportOnDate={new Date()} displayDataSource={0} filterByColumn={"siteUrl"} />
                                    </Paper>
                                </Grid>
                                */}

                                { /* Display a link to Insights Search. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Search your Audit Records</Typography>
                                            <br />
                                            <div style={{ textAlign: 'center' }}>
                                                <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                                                <Link onClick={() => navigate(`/Reports/InsightsSearch/All`)} component="button">Configure and run an Insights Search.</Link>
                                            </div>
                                        </div>
                                        <br />
                                    </Paper>
                                </Grid>
                                { /* Display links to reports by workload. */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Reports by Workload</Typography>
                                            <br />
                                            <TableContainer>
                                                <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/SharePoint`)} component="button"><img src={ImageSharePoint} alt="SharePoint Reports" /></Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Exchange`)} component="button"><img src={ImageExchange} alt="Exchange Reports" /></Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/EntraID`)} component="button"><img src={ImageEntraID} alt="Entra ID (Azure AD)" /></Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}>
                                                                <Link onClick={() => navigate(`/Reports/Teams`)} component="button">
                                                                    <svg viewBox="-0.12979372698077785 0 32.42343730730004 32" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px"><circle cx="17" cy="6" fill="#7b83eb" r="4.667" /><path d="M16.667 7H12.44l.021.093.002.008.022.086A4.671 4.671 0 0 0 18 10.559V8.333A1.337 1.337 0 0 0 16.667 7z" opacity=".1" /><path d="M15.667 8h-2.884A4.667 4.667 0 0 0 17 10.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" /><circle cx="27.5" cy="7.5" fill="#5059c9" r="3.5" /><path d="M30.5 12h-7.861a.64.64 0 0 0-.64.64v8.11a5.121 5.121 0 0 0 3.967 5.084A5.006 5.006 0 0 0 32 20.938V13.5a1.5 1.5 0 0 0-1.5-1.5z" fill="#5059c9" /><path d="M25 13.5V23a7.995 7.995 0 0 1-14.92 4 7.173 7.173 0 0 1-.5-1 8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5h13a1.498 1.498 0 0 1 1.5 1.5z" fill="#7b83eb" /><path d="M15.667 8h-2.884A4.667 4.667 0 0 0 17 10.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" /><path d="M18 12v12.67a1.32 1.32 0 0 1-1.04 1.29.966.966 0 0 1-.29.04H9.58a8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".1" /><path d="M17 12v13.67a.967.967 0 0 1-.04.29A1.32 1.32 0 0 1 15.67 27h-5.59a7.173 7.173 0 0 1-.5-1 8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".2" /><path d="M17 12v11.67A1.336 1.336 0 0 1 15.67 25H9.25A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".2" /><path d="M10.5 12A1.498 1.498 0 0 0 9 13.5V23a8.24 8.24 0 0 0 .25 2h5.42A1.336 1.336 0 0 0 16 23.67V12z" opacity=".2" /><path d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333 0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z" fill="#4b53bc" /><path d="M11.98 12.975H8.99v8.02H7.028v-8.02H4.02v-1.97h7.96z" fill="#fff" /><path d="M0 0h32v32H0z" fill="none" /></svg>
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/SharePoint`)} component="button">SharePoint</Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Exchange`)} component="button">Exchange</Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/EntraID`)} component="button">Entra ID</Link></TableCell>
                                                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Teams`)} component="button">Teams</Link></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </div>
                                        <br />
                                    </Paper>
                                </Grid>
                                { /* Display a link to Diagnostic Logs.  */}
                                <Grid item xs={4} sx={{ minWidth: 380, padding: 2 }}>
                                    <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                        <div style={{ height: '100%' }}>
                                            <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Your Audit Job Diagnostic Logs</Typography>
                                            <br />
                                            <div style={{ textAlign: 'center' }}>
                                                <NotificationImportantIcon style={{ fontSize: '3rem' }} />&nbsp;
                                                <Link onClick={() => navigate(`/Reports/DiagnosticsReport/${focusTenantId}`)} component="button">View Last 7 Days</Link>
                                            </div>
                                        </div>
                                        <br />
                                    </Paper>
                                </Grid>
                            </>
                        }

                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <br />
                            Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                            <br />* Unless otherwise specified as UTC, graphs on page are using your browser's local timezone.
                        </Grid>
                    </Grid>
                </div >
            </>
        );
    }
    else {
        // Company does not yet have a tenant specified.
        return (
            <>
                <TrialPeriodNag company={company}></TrialPeriodNag>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />

                <div>
                    <Grid container spacing={2}>
                        { /* Display if tenant is still pending.  Else company is not yet finished setup or there are no valid tenants. */}
                        {tenantHealthStats.status === 0 ?
                            (
                                <>
                                    <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                        <TenantSetupRequired />
                                    </Grid>

                                    <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                        <Grid item>
                                            <TenantDropDown
                                                user={user}
                                                company={company}
                                                handleTenantOptionChange={handleTenantOptionChange}
                                                selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                        </Grid>
                                    </Grid>

                                </>
                            ) :
                            (
                                <Grid item xs={12}>
                                    <ProgressBar message="Loading ..." loading={true} />
                                </Grid>
                            )
                        }
                        <Grid item xs={12}>
                            <br />
                            Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        </Grid>
                    </Grid>
                </div>
            </>
        );

    }
}
export default Reports;